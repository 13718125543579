import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';
import { RestApiService } from './api.service';
import { HttpClientService } from './http-client.service';

export interface IEntity {
  entityId: string,
  entityType: string;
}

export interface ILocation {
  type: 'Point';
  // coordinates: [number, number];
  coordinates: [string, string];
}

export interface IImageUpload {
  s3FileName: string;
  originalFileName: string;
  photoUrl?: {
    url: string,
    expiresIn: number;
  };

}

export interface IAsset extends IImageUpload {
  contentType: string,
  fileType: string,
  fileUrl?: {
    url: string,
    expiresIn: number;
  };
}

export interface ICuisine {
  cuisineId: string;
  cuisineName: string;
  cuisineAliasName?: string;
  cuisineDescription?: string;
}

export interface IBrandOnboardResponse {
  storeFrontBrandId: string;
  preScreening: IPreScreening;
  taxAndBankDetails: IBankTaxDetails;
  verification: IVerification;
  brandSetup: IBrandSetup;
  status?: string;
  remarks?: string;
  isBrandOnboarded?: boolean;
}

/* Verification */
export interface IVerification {
  documents: {
    productionUnit: IAsset[];
    productsAndCatalogue: IAsset[];
  };
  status: IStatus;
}



/* Pre-screening */

interface IStatus {
  // isDraft: boolean;
  // isSubmitted: boolean;
  currentState: 'draft' | 'submitted';
}


interface IBasicInformation {
  brandName: string;
  brandManagerOrPOC: BrandManagerOrPOC;
  email: string;
  webSiteLink: string;
}


interface BrandManagerOrPOC {
  name: string;
  contactNumber: ContactNumber;
}

interface ContactNumber {
  primary: string;
  secondary: string;
}

interface IRegisteredOfficeAddress {
  address: string;
  district: string;
  nearestLandmark: string;
  state: string;
  pincode: number;
}

interface IFssaiCertificateImage {
  fssaiNumber: number;
  fssaiExpiryDate: string;
  certificateImage: IAsset;
}


interface ITermsAndConditionAgreement {
  isAccepted: boolean;
}

interface IPreScreening {
  basicInformation: IBasicInformation;
  registeredOfficeAddress: IRegisteredOfficeAddress;
  fssaiCertificateDetails: IFssaiCertificateImage;
  termsAndConditionAgreement: ITermsAndConditionAgreement;
  status: IStatus;
}


/* Bank Details */

export interface IBankTaxDetails {
  gstIn: string;
  panCard: string;
  udyogAadhaar: string;
  bankDetails: IBankDetails;
  isGstInVerified: boolean;
  isPanVerified: boolean;
  status: IStatus;
}

interface IBankDetails {
  accountHolderName: string;
  accountNumber: number;
  confirmAccountNumber: number;
  ifscCode: string;
  cancellationChequeOrBankPassBookImage: IAsset;
}

export interface IPersonalDetails {
  age?: number,
  title: string;
  altPhoneNumber: number,
  emailId: string,
  pickUpLocation: {
    location?: {
      type?: string,
      coordinates?: number[],
    };
    address: string,
    landmark: string,
    city: string,
    state: string,
    pinCode: string,
  };
  isDrafted: boolean;
}

export interface IFssaiDetails {
  fssaiNumber?: string,
  fssaiCertificateImage?: IImageUpload,
  fssaiReferenceNumber?: string,
  isTermsAndConditionsAgreed: boolean,
  isDrafted: boolean,
  fssaiExpiryDate: string;
}

export interface IVisualVerificationDetails {
  kitchenOrMftPlaceImage: Array<IImageUpload>,
  isSellerReady: boolean,
  isDrafted: boolean;
}

export interface createCuisine {
  cuisineName: string;
  cuisineAliasName: string;
  cuisineDescription: string;
}

export interface IVerifyGST {
  legal_name_of_business: string;
  trade_name_of_business: string;
  nature_of_business_activities: Array<string>;
  principal_place_address: string;
}
export interface IGenerateOTP {
  ref_id: string;
  status: string;
  message: Array<string>;
}

export interface IVerifyOTP {
  ref_id: string;
  otp: string;
}

export enum ScreeningStatus {
  PENDING = 'pending',
  WAITING = 'waiting',
  REJECTED = 'rejected',
}

export enum SellerStatus {
  INITIATE_SCREENING = 'initiateScreening',
  INITIATE_INSPECTING = 'initiateInspecting',
  INITIATE_ONBOARDING = 'initiateOnboarding'
}

export enum KitchenInspectionStatus {
  SCHEDULED = 'scheduled',
  RESCHEDULED = 'reScheduled',
  UNSCHEDULED = 'unScheduled'
}

export interface ISubZoneDetails {
  parentZoneId: string;
  subZoneId: string;
  active: boolean;
  subZoneName: string;
  subZoneAliasName: string;
  inactiveMessageId: string;
  createdAt: string;
  updatedAt: string;
  radiusInKms: string;
}

export interface ICallSupport {
  sellerInfoId: string,
  phoneNumber: string,
  parentZoneId?: string,
  subZoneId?: string;
}

interface BrandProfile {
  brandName: string;
  brandAssets: BrandAsset[];
  badges: string[];
  certificateAndLabTestReportAssets: CertificateAndLabTestReportAsset[];
}

interface BrandAsset {
  s3FileName: string;
  originalFileName: string;
  fileUrl: FileUrl;
  contentType: string;
  fileType: string;
}

interface CertificateAndLabTestReportAsset {
  s3FileName: string;
  originalFileName: string;
  fileUrl: FileUrl;
  contentType: string;
  fileType: string;
}

interface FileUrl {
  url: string;
  expiresIn: number;
}

interface Status {
  isDraft: boolean;
  isSubmitted: boolean;
  updatedAt: string;
  createdAt: string;
}

export interface IBrandSetup {
  brandProfile: BrandProfile;
  status: IStatus;
  approvalStatus?: string;
  remarks?: string;
}

export interface IBadge {
  _id: string;
  badgeName: string;
  badgeImage: BadgeImage;
  badgeId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface BadgeImage {
  s3FileName: string;
  originalFileName: string;
  fileUrl: FileUrl;
  contentType: string;
  fileType: string;
}

interface FileUrl {
  url: string;
  expiresIn: number;
}
export function removeEmptyValues(payload: any): any {
  if (Array.isArray(payload)) {
    return payload
      .map(removeEmptyValues)
      .filter(item => {
        if (Array.isArray(item) && item.length === 0) return false; // Remove empty arrays
        if (typeof item === 'object' && item !== null && Object.keys(item).length === 0) return false; // Remove empty objects
        return item !== null && item !== undefined && item !== ''; // Keep non-empty values
      });
  } else if (typeof payload === 'object' && payload !== null) {
    return Object.fromEntries(
      Object.entries(payload)
        .map(([key, value]) => [key, removeEmptyValues(value)])
        .filter(([, value]) => {
          if (value === 0) return true; // Keep if value is zero
          if (typeof value === 'boolean') return true;
          if (Array.isArray(value) && value.length === 0) return false; // Remove if empty array
          if (typeof value === 'string' && value.length === 0) return false; // Remove if empty string
          if (value === undefined || value === null) return false;
          if (typeof value === 'object' && Object.keys(value).length === 0) return false; // Remove if empty object
          return value !== undefined && value !== null; // Remove undefined, null, or false
        })
    );
  } else {
    return payload;
  }
}


@Injectable()
export class BrandOnboardApiService extends RestApiService {

  protected override baseApiUrl = environment.config['SELLER_PORTAL_SERVICE_URI'];
  private kitchenApiUrl = environment.config['KITCHEN_SERVICE_URI'];

  private servicePath = `${this.baseApiUrl}/storefront-portal`;

  private localStorageBrandInfo = localStorage.getItem('storeFrontBrandInfo')

  public brandDetails = this.localStorageBrandInfo ? JSON.parse(this.localStorageBrandInfo) : {};

  private brandId = this.brandDetails?.storeFrontBrandId;

  // private brandId = localStorage.getItem('storeFrontBrandId') || '';

  // private brandId = 'OCDPHFBS7U2bnWu3CtiGq';

  private headers: any;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;

    this.headers = {
      'x-auth-provider': 'google',
      'user-current-view': 'cook',
    };
  }

  async createPreScreeningDetails(payload: IPreScreening, brandId: string = this.brandId): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/${brandId}/pre-screening`;
    const apiPayload = {
      preScreening: payload
    };
    return this.httpPost(apiPath, apiPayload, this.getHeaders());
  }

  async updatePreScreeningDetails(payload: IPreScreening, brandId: string = this.brandId): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/${brandId}/pre-screening`;
    const apiPayload = {
      preScreening: payload
    };
    return this.httpPut(apiPath, apiPayload, this.getHeaders());
  }

  async createBankTaxDetails(payload: IBankTaxDetails, brandId: string = this.brandId): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/${brandId}/tax-bank-details`;
    const apiPayload = {
      taxAndBankDetails: payload
    };

    return this.httpPost(apiPath, apiPayload, this.getHeaders());
  }

  async updateBankTaxDetails(payload: IBankTaxDetails, brandId: string = this.brandId): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/${brandId}/tax-bank-details`;
    const apiPayload = {
      taxAndBankDetails: payload
    };
    return this.httpPut(apiPath, apiPayload, this.getHeaders());
  }

  async verifyPanOrGst(docType: 'gst' | 'pan-card', payload: { documentNumber: string; }, brandId: string = this.brandId): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/document-verification/${brandId}?type=${docType}`;
    return this.httpPost(apiPath, payload, this.getHeaders());
  }

  async verifyPan(sellerInfoId: string, payload: { pan: string; }): Promise<{ data: any; }> {
    const apiPath = `${this.servicePath}pan-card-verification/${sellerInfoId}`;
    return this.httpPost(apiPath, payload, this.getHeaders());
  }

  async generateOtp(payload: { aadhaarNumber: string; }): Promise<{ data: IGenerateOTP; }> {
    const apiPath = `${this.servicePath}aadhaar-verification/generate-otp`;
    return this.httpPost(apiPath, payload, this.getHeaders());
  }

  async verifyOtp(payload: IVerifyOTP): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}aadhaar-verification/verify-otp/${this.brandId}`;
    return this.httpPost(apiPath, payload, this.getHeaders());
  }

  async createVisualVerificationDetails(payload: IVerification, brandId: string = this.brandId): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/${brandId}/verification`;
    const apiPayload = {
      verification: payload
    };
    return this.httpPost(apiPath, apiPayload, this.getHeaders());
  }

  async updateVisualVerificationDetails(payload: IVerification, brandId: string = this.brandId): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/${brandId}/verification`;
    const apiPayload = {
      verification: payload
    };
    return this.httpPut(apiPath, apiPayload, this.getHeaders());
  }

  async getSellerInfoByPhoneNumber(phoneNum: string): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/details?phoneNumber=${phoneNum}`;
    return this.httpGet(apiPath, this.getHeaders());
  }

  async getBrandDetailsById(brandId: string): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}/${brandId}`;
    return this.httpGet(apiPath, this.getHeaders());
  }

  async updateSellerInfo(payload: IBrandOnboardResponse, sellerInfoId: string = this.brandId): Promise<{ data: IBrandOnboardResponse; }> {
    const apiPath = `${this.servicePath}${sellerInfoId}`;
    return this.httpPut(apiPath, payload, this.getHeaders());
  }

  async getLocationByCoordinates(lat: string, lng: string): Promise<{ data: { subZones: Array<ISubZoneDetails>; }; }> {
    const apiPath = `${this.servicePath}locate/sub-zone?long=${lng}&lat=${lat}`;
    return this.httpGet(apiPath, this.getHeaders());
  }

  async refreshTokenApi(): Promise<any> {
    const storeFrontBrandDetails = getBrandInfoFromLocalStorage();
    if (storeFrontBrandDetails) {
      const payload = {
        refreshToken: storeFrontBrandDetails?.refreshToken
      };
      const apiPath = `${this.baseApiUrl}/gcp-store/refreshToken`;
      return this.httpPost(apiPath, payload);
    }
  }

  async initiateCallSupport(payload: ICallSupport) {
    const apiPath = `${this.servicePath}initiate-call-support`;
    return this.httpPost(apiPath, payload, this.getHeaders());
  }

  async getBrandProfileById(id: string): Promise<{ data: IBrandSetup; }> {
    const apiPath = `${this.servicePath}/${id}/brand-setup`;
    return this.httpGet(apiPath, this.getHeaders());
  }

  async getBrandProfileByIdAfterProfileApproved(id: string): Promise<{ data: IBrandSetup; }> {
    const apiPath = `${this.kitchenApiUrl}/storefront-brand/common/profile/approval/${id}`;
    return this.httpGet(apiPath, this.getHeaders());
  }

  async getStoreFrontBadge(): Promise<{ data: IBadge[]; }> {
    const apiPath = `${this.baseApiUrl}/storefront-badge`;
    return this.httpGet(apiPath, this.getHeaders());
  }

  async createBrandProfile(brandId: string, payload: IBrandSetup) {
    const apiPath = `${this.servicePath}/${brandId}/brand-setup`;
    const apiPayload = {
      brandSetup: payload
    };
    return this.httpPost(apiPath, apiPayload, this.getHeaders());
  }

  async updateBrandProfileById(brandId: string, payload: IBrandSetup) {
    const apiPath = `${this.servicePath}/${brandId}/brand-setup`;
    const apiPayload = {
      brandSetup: payload
    };
    return this.httpPut(apiPath, apiPayload, this.getHeaders());
  }
  async updateBrandProfileByIdAfterProfileApproved(id: string, payload: any) {
    const apiPath = `${this.kitchenApiUrl}/storefront-brand/${id}`;
    return this.httpPut(apiPath, payload, this.getHeaders());
  }

  getHeaders() {
    return {
      ...this.headers,
      idToken: localStorage.getItem('idToken')
    }
  }
}

export function extensionContentType(type: string) {
  const imageFormat = ['png', 'jpeg', 'jpg', 'webp'];
  const videoFormat = ['mp4', 'webm'];
  const fileType = type.toLowerCase();
  if (imageFormat.includes(fileType)) return `image/${fileType}`;
  if (videoFormat.includes(fileType)) return `video/${fileType}`;
  return fileType === 'pdf' || fileType === 'docx' || fileType === 'xlsx' || fileType === 'xls' ? `application/${fileType}` : `image/${fileType}`;
}


export function getBrandInfoFromLocalStorage() {
  const storeFrontBrandDetails = localStorage.getItem('storeFrontBrandInfo');
  if (storeFrontBrandDetails) return JSON.parse(storeFrontBrandDetails);
  return null;
}

export { IPreScreening };