import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SellerOnboarAuthGuard } from '../guards/seller-onboard-auth.guard';
import { BrandOnboardAuthGuard } from 'src/guards/brand-onboard-auth-guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrandPortalComponent } from './brand-portal/brand-portal.component';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'seller-onboard',
    loadChildren: () => import('./seller-portal/seller-portal.module').then((m) => m.SellerPortalModule),
  },
  {
    path: 'seller-onboard',
    canActivate: [SellerOnboarAuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./seller-portal/seller-onboard/seller-onboard.module').then((m) => m.SellerOnboardModule),
  },
  {
    path: 'cook-profile',
    loadChildren: () => import('./seller-portal/cook-profile/cook-profile.module').then((m) => m.CookProfileModule),
  },
  {
    path: 'brand-portal',
    loadChildren: () => import('./brand-portal/brand-portal.module').then(m => m.BrandPortalModule)
  },
  {
    path: 'brand-onboard/:brandStoreFrontId',
    canActivate: [BrandOnboardAuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./brand-portal/brand-onboard/brand-onboard.module').then((m) => m.BrandOnboardModule),
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'brand-profile/:brandStoreFrontId',
    canActivate: [BrandOnboardAuthGuard],
    loadChildren: () => import('./brand-portal/brand-profile/brand-profile.module').then((m) => m.BrandProfileModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
